<template>
  <div id="app">
    <aConfigProvider :locale="locale">
      <aLayout v-if="$store.state.isLoggedIn" class="dashboard">
        <DesktopNavigation v-if="$root.windowWidth > 961" />
        <aLayout class="layout-scroll">
          <aLayoutContent class="layout-content">
            <RouterView />
          </aLayoutContent>
        </aLayout>

        <aModal
          :visible="showDisconnectedLoginModal"
          :title="false"
          :footer="false"
          :width="300"
          :closable="!tokenExpired ? true : false"
          @ok="showDisconnectedLoginModal = true"
          @cancel="showDisconnectedLoginModal = true"
        >
          <p
            class="mb-10"
            style="font-size: 16px; font-weight: 600; color: #be4178"
          >
            Seu acesso expirou.
          </p>
          <p class="mb-10" style="font-size: 11px; color: #999">
            Para continuar sua navegação, faça login novamente.
          </p>
          <LoginFormModal
            :updateStorageData="true"
            @closeDisconnectedLoginModal="closeDisconnectedLoginModal"
          />
        </aModal>
      </aLayout>
      <router-view v-else />
    </aConfigProvider>
  </div>
</template>

<script>
import ptBr from "ant-design-vue/lib/locale-provider/pt_BR";
import { isJwtExpired } from "jwt-check-expiration";
import { mapState, mapActions } from "vuex";

import DesktopNavigation from "@/components/general/header/DesktopNavigation.vue";
import LoginFormModal from "@/components/login/LoginFormModal.vue";

export default {
  components: {
    DesktopNavigation,
    LoginFormModal,
  },
  data() {
    return {
      isLoggedIn: this.$store.isLoggedIn,
      locale: ptBr,
      tokenExpired: false,
      showDisconnectedLoginModal: false,
      scrollYOffset: 0,
      navItens: [],
    };
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  beforeMount() {
    this.setCapabilities();
  },
  updated() {
    this.checkJWT();
  },
  mounted() {
    const scrollContent = document.querySelector(".layout-scroll");
    scrollContent.addEventListener("scroll", () => {
      const scrolled = scrollContent.scrollTop;
      this.scrollYOffset = scrolled;
    });
    setInterval(() => {
      this.checkJWT();
    }, 30000);
  },
  methods: {
    checkJWT() {
      this.tokenExpired = isJwtExpired(localStorage.getItem("token"));
      setTimeout(() => {
        if (this.tokenExpired) {
          setTimeout(() => {
            this.showDisconnectedLoginModal = true;
          }, 5000);
        } else {
          this.showDisconnectedLoginModal = false;
        }
      }, 5000);
    },
    ...mapActions(["setCapabilities"]),
    closeDisconnectedLoginModal() {
      this.showDisconnectedLoginModal = false;
      this.tokenExpired = false;

      this.$store.state.tokenExperation =
        localStorage.getItem("tokenExpiration");
    },
  },
};
</script>

<style lang="sass">
@import '/assets/sass/style.sass'
</style>

<style lang="sass" scoped>
.dashboard
  .ant-layout-sider
    overflow: visible !important
</style>

<style>
#img-uploaded {
  max-width: 100%;
}
</style>

<style scoped>
main.layout-content.ant-layout-content {
  background: #f1f1f2 !important;
}
</style>
