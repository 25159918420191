<template>
  <section class="login">
    <aRow
      :gutter="[20, 20]"
      align="middle"
      justify="center"
      type="flex"
      style="margin: 0"
    >
      <aCol class="form" :span="$root.windowWidth > 1130 ? 7 : 24">
        <div style="margin: 0 auto; width: 300px">
          <ForgotPasswordForm v-if="$route.meta.slug === 'forgot-password'" />
          <ChangePassword v-else-if="$route.meta.slug === 'key'" />
          <LoginForm v-else />
        </div>
      </aCol>
      <aCol v-show="$root.windowWidth > 1130" class="bg" :span="17">
        <div class="image" />
        <div class="txt txt1">Bem Vindo ao</div>
        <div class="txt txt2">HAYA!</div>
        <div class="txt txt3">
          Aprendizado, organização e produção 100% online.
        </div>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import LoginForm from "@/components/login/LoginForm";
import ForgotPasswordForm from "@/components/login/ForgotPasswordForm";
import ChangePassword from "@/components/login/ChangePassword.vue";

export default {
  components: { LoginForm, ForgotPasswordForm, ChangePassword },
  beforeCreate() {
    if (this.$store.state.isLoggedIn) this.$router.push(`/`);
  },
};
</script>
