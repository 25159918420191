var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoginSuccessful)?[_c('aForm',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aFormItem',{staticClass:"mb-10"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido.',
                },
                {
                  required: true,
                  message: 'Escreva seu e-mail no campo.',
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: 'Escreva um e-mail válido.',\n                },\n                {\n                  required: true,\n                  message: 'Escreva seu e-mail no campo.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira seu e-mail"}},[_c('aIcon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('aFormItem',{staticClass:"mb-10"},[_c('aInputPassword',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Escreva sua senha.',
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Escreva sua senha.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"type":"password","placeholder":"Insira sua senha"}},[_c('aIcon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('aFormItem',{staticClass:"mb-0"},[_c('aButton',{staticClass:"mt-10",staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v("\n          Login\n        ")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.isLoginSuccessful)?[_c('AuthenticatorForm',{attrs:{"twoFactorsIsEnabled":_vm.twoFactorsIsEnabled,"userEmail":_vm.userEmail,"isLoginPage":false,"updateStorageData":_vm.updateStorageData},on:{"verificationSuccessfully":_vm.verificationSuccessfully}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }