
<template>
    <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a @click="$router.go(-1)"> <a-icon type="arrow-left" /> Voltar </a>

        <div v-show="sent">
            <a-alert
                class="mt-20"
                type="success"
                message="Sucesso!"
                show-icon
                banner
            >
                <div slot="description" style="font-size: 13px">
                    Um e-mail de redefinição de senha foi enviado para o seu
                    endereço de e-mail, mas pode levar alguns minutos para
                    aparecer na sua caixa de entrada. <br />
                    <br />
                    Aguarde pelo menos 10 minutos antes de tentar novamente ou
                    verifique sua caixa de spam.
                </div>
            </a-alert>
        </div>

        <div v-show="!sent">
            <h1 class="mt-30">Recuperar Senha</h1>

            <a-alert
                class="mb-10 mt-10"
                message="Perdeu sua senha?  Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha."
                type="info"
                banner
                style="font-size: 13px"
            />

            <a-form-item>
                <a-input
                    class="travel-input"
                    size="large"
                    v-decorator="[
                        'email',
                        {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'Escreva um e-mail válido.',
                                },
                                {
                                    required: true,
                                    message: 'Escreva seu e-mail no campo.',
                                },
                            ],
                        },
                    ]"
                    placeholder="Insira seu e-mail"
                >
                    <a-icon
                        slot="prefix"
                        type="mail"
                        style="color: rgba(0, 0, 0, 0.25)"
                    />
                </a-input>
            </a-form-item>

            <a-form-item>
                <a-button
                    type="primary"
                    size="large"
                    html-type="submit"
                    :loading="loading"
                >
                    Enviar
                </a-button>
            </a-form-item>
        </div>
    </a-form>
</template>

<script>
export default {
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            sent: false,
        };
    },
    mounted() {},
    methods: {
        recoverPassword(userData) {
            this.$http
                .post("/user/forgot-password", userData)
                .then(({ data }) => {
                    console.log(data.message);
                    this.$message.success("Sucesso!");
                    //this.$message.success(data.message);
                    this.loading = false;
                    this.sent = true;
                })
                .catch(({ response }) => {
                    this.$message.error(response.data.message);
                    this.loading = false;
                    this.sent = false;
                });
        },
        handleSubmit(e) {
            e.preventDefault();
            this.loading = true;
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.recoverPassword(values);
                } else {
                    this.loading = false;
                    this.sent = false;
                }
            });
        },
    },
};
</script>