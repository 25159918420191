import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import { RouterTabRoutes } from "vue-router-tab";

import Login from "@/views/Login.vue";
import SystemTabContent from "@/components/general/content/SystemTabContent.vue";
import contractsImg from "@/assets/images/dashboard/tabnav/contracts.png";
import salesImg from "@/assets/images/dashboard/tabnav/sales.png";
import calendarImg from "@/assets/images/dashboard/tabnav/calendar.png";
import milesImg from "@/assets/images/dashboard/tabnav/miles.png";
import customersImg from "@/assets/images/dashboard/tabnav/customers.png";
import mktImg from "@/assets/images/dashboard/tabnav/marketing.png";
import settingsImg from "@/assets/images/dashboard/tabnav/admin.png";
import pipeImg from "@/assets/images/dashboard/tabnav/pipeline.png";
import emailImg from "@/assets/images/dashboard/tabnav/emails.png";
import codeImg from "@/assets/images/dashboard/tabnav/code-img.png";
import financialImg from "@/assets/images/dashboard/tabnav/financial.png";

Vue.use(Vuex);
Vue.use(VueRouter);

const company = "Haya";

const importViews = (view) => () => import(`@/views/${view}.vue`),
  systemPages = (view) => () => import(`@/components/${view}.vue`),
  contracts = [
    {
      path: "/contracts/list",
      component: importViews("contracts/ContractsListV2"),
      meta: {
        title: `Contratos`,
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "contracts",
        icon: contractsImg,
        key: "path",
      },
      children: [],
    },
    {
      name: "edit",
      path: "/contracts/edit/:id",
      component: importViews("contracts/ContractPage"),
      meta: {
        title: `Contratos`,
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        icon: "edit",
        module: "contracts",
        key: "path",
      },
    },
    {
      name: "new",
      path: "/contracts/new",
      component: importViews("contracts/ContractPage"),
      meta: {
        title: `Contratos`,
        showTitle: true,
        requiresAuth: true,
        slug: "new",
        module: "contracts",
        key: "path",
        icon: "file",
      },
    },
    {
      path: "/contracts/view/:id",
      component: importViews("contracts/ViewContractPageV2"),
      meta: {
        title: `Contratos`,
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "contracts",
        key: "path",
        icon: "eye",
      },
    },
    {
      path: "/contracts/report/products",
      component: importViews("contracts/subpages/ContractProductsReport"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "products",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
    {
      path: "/contracts/report/customer-origin",
      component: importViews("contracts/subpages/ContractCustomerOriginReport"),
      meta: {
        title: `Relatório de origem do cliente`,
        showTitle: true,
        requiresAuth: true,
        slug: "customer-origin",
        module: "contracts",
        key: "path",
        icon: "user",
      },
    },
    {
      path: "/contracts/report/productivity",
      component: importViews("contracts/subpages/ContractProductivityReport"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "productivity",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
    {
      path: "/contracts/report/suppliers",
      component: importViews("contracts/subpages/ContractSuppliersReport"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "suppliers",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
    {
      path: "/contracts/report/departures",
      component: importViews("contracts/subpages/ContractDeparturesReport"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "departures",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
    {
      path: "/contracts/report/departures-to-pay",
      component: importViews("contracts/subpages/ContractDeparturesToPay"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "departures-to-pay",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
    {
      path: "/contracts/report/payments",
      component: importViews("contracts/subpages/ContractPaymentsReport"),
      meta: {
        title: `Relatórios`,
        showTitle: true,
        requiresAuth: true,
        slug: "payments",
        module: "contracts",
        key: "path",
        icon: "",
      },
    },
  ],
  products = [
    {
      path: "/products/list",
      component: importViews("products/ProductsList"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "products",
        icon: contractsImg,
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/products/edit/:id",
      component: importViews("products/ProductPage"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        icon: "edit",
        module: "products",
        key: "path",
      },
    },
    {
      name: "new",
      path: "/products/new",
      component: importViews("products/ProductPage"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "new",
        module: "products",
        key: "path",
        icon: "file",
      },
    },
    {
      path: "/products/view/:id",
      component: importViews("products/ProuductViewPackagePage"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "products",
        key: "path",
        icon: "eye",
      },
    },
    {
      path: "/products/budget",
      component: importViews("products/ProductsBudgetList"),
      meta: {
        title: `Orçamento Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "budget",
        module: "products-budget",
        icon: contractsImg,
        key: "path",
      },
    },
    {
      name: "products-budget-edit",
      path: "/products-budget/edit/:id",
      component: importViews("products/ProductPage"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        icon: "edit",
        module: "products-budget",
        key: "path",
      },
    },
    {
      name: "products-budget-view",
      path: "/products-budget/view/:id",
      component: importViews("products/ProuductViewPackagePage"),
      meta: {
        title: `Produtos`,
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "products-budget",
        key: "path",
        icon: "eye",
      },
    },
  ],
  documentations = [
    {
      path: "/documentations/list",
      component: importViews("documentations/DocumentationsList"),
      meta: {
        title: `Documentações`,
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "documentations",
        icon: codeImg,
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/documentations/edit/:id",
      component: importViews("documentations/DocumentationPage"),
      meta: {
        title: `Documentações`,
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        icon: "edit",
        module: "documentations",
        key: "path",
      },
    },
    {
      path: "/documentations/view/:id",
      component: importViews("documentations/DocumentationView"),
      meta: {
        title: `Documentações`,
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "documentations",
        key: "path",
        icon: "eye",
      },
    },
  ],
  creditLetters = [
    {
      path: "/credit-letters/list",
      component: importViews("credit-letters/CreditLettersList"),
      meta: {
        title: `Cartas de Crédito`,
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "credit-letters",
        icon: contractsImg,
        key: "path",
      },
    },
  ],
  pipeline = [
    {
      path: "/pipeline",
      component: importViews("pipeline/Pipeline"),
      meta: {
        title: `Pipeline`,
        showTitle: true,
        requiresAuth: true,
        slug: "pipeline",
        module: "pipeline",
        icon: pipeImg,
        key: "path",
      },
    },
    {
      path: "/pipeline/funnels-and-steps",
      component: importViews("pipeline/FunnelsAndSteps"),
      meta: {
        title: `Funis e Etapas`,
        showTitle: true,
        requiresAuth: true,
        slug: "funnels-and-steps",
        module: "pipeline",
        icon: pipeImg,
        key: "path",
      },
    },
  ],
  email = [
    {
      path: "/emails",
      component: importViews("email/Emails"),
      meta: {
        title: `Emails`,
        showTitle: true,
        requiresAuth: true,
        slug: "email",
        module: "email",
        icon: emailImg,
        key: "path",
      },
    },
  ],
  financial = [
    {
      path: "/financial/generate-invoices",
      component: importViews("finances/GenerateInvoicesList"),
      meta: {
        title: `Gerar Faturas`,
        icon: financialImg,
        showTitle: true,
        requiresAuth: true,
        slug: "generate-invoices",
        module: "financial",
        key: "path",
      },
    },
    {
      path: "/financial/invoices",
      component: importViews("finances/InvoicesList"),
      meta: {
        title: `Faturas`,
        icon: financialImg,
        showTitle: true,
        requiresAuth: true,
        slug: "invoices",
        module: "financial",
        key: "path",
      },
    },
    {
      path: "/financial/banks",
      component: importViews("finances/BanksPage"),
      meta: {
        title: `Bancos`,
        icon: financialImg,
        showTitle: true,
        requiresAuth: true,
        slug: "banks",
        module: "financial",
        key: "path",
      },
    },
  ],
  sales = [
    {
      path: "/sales/list",
      component: importViews("sales/ListSales"),
      meta: {
        title: `Vendas`,
        showTitle: true,
        icon: salesImg,
        requiresAuth: true,
        slug: "list",
        module: "sales",
        key: "path",
      },
    },
    {
      path: "/sales/report/bills-to-receive",
      component: importViews("sales/SalesBillsToReceiveReport"),
      meta: {
        title: `Contas a Receber - Relatório`,
        icon: salesImg,
        showTitle: true,
        requiresAuth: true,
        slug: "bills-to-receive",
        module: "sales",
        key: "path",
      },
    },
  ],
  events = [
    {
      path: "/events/calendar",
      component: importViews("calendar/Calendar"),
      props: true,
      meta: {
        title: `Calendário`,
        requiresAuth: true,
        icon: calendarImg,
        slug: "events",
        showTitle: true,
        module: "events",
        key: "path",
      },
    },
    {
      name: "list",
      path: "/events/list",
      component: importViews("calendar/ListEvents"),
      props: true,
      meta: {
        title: `Lista de Atividades`,
        requiresAuth: true,
        icon: "calendar",
        slug: "events",
        showTitle: true,
        module: "events",
        key: "path",
      },
    },
  ],
  miles = [
    {
      path: "/miles-cards/list",
      component: importViews("miles/MilesCardsList"),
      meta: {
        title: `Controle de Estoque`,
        showTitle: true,
        icon: milesImg,
        requiresAuth: true,
        slug: "list",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      name: "view",
      path: "/miles-cards/view/:id",
      component: importViews("miles/MilesCardsView"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Cartão de Milhas`;
          return ` C. Milhas ${route.params.id}`;
        },
        icon: "eye",
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-finances/payments",
      component: systemPages("finances/miles/payments/ListPayments"),
      meta: {
        title: `Pagamentos`,
        showTitle: true,
        requiresAuth: true,
        icon: "unordered-list",
        slug: "payments",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      name: "view",
      path: "/miles-finances/payments/view/:id",
      component: systemPages("finances/miles/payments/ViewPayments"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Detalhes do Pagamento`;
          return `Pagamento ${route.params.id}`;
        },
        icon: "eye",
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/buy-miles",
      component: systemPages("miles/BuyMiles/BuyMiles"),
      meta: {
        title: `Comprar Milhas`,
        showTitle: true,
        icon: "",
        requiresAuth: true,
        slug: "buy-miles",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/miles",
      component: importViews("miles/reports/MilesCardsMilesManagement"),
      meta: {
        title: `Relatório Milhas`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "miles",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/emissions",
      component: importViews("miles/reports/MilesCardsEmissions"),
      meta: {
        title: `Relatório de Emissões`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "emissions",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/blocks",
      component: importViews("miles/reports/MilesCardsBlocks"),
      meta: {
        title: `Relatório de Bloqueios`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "blocks",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/purchases",
      component: importViews("miles/reports/MilesCardsPurchases"),
      meta: {
        title: `Relatório de Compras`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "purchases",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/card-taxes",
      component: importViews("miles/reports/MilesCardsTaxes"),
      meta: {
        title: `Relatório de Taxas`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "card-taxes",
        module: "miles-cards",
        key: "path",
      },
    },
    {
      path: "/miles-cards/reports/refund",
      component: importViews("miles/reports/MilesCardsRefund"),
      meta: {
        title: `Relatório de Reembolsos`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "refund",
        module: "miles-cards",
        key: "path",
      },
    },
  ],
  milesOrders = [
    {
      path: "/miles-orders/list",
      component: importViews("orders/ListMilesOrders"),
      meta: {
        title: `Pedidos de Milhas`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "miles-orders",
        key: "path",
      },
    },
  ],
  suppliers = [
    {
      path: "/suppliers/list",
      component: importViews("suppliers/SuppliersList"),
      meta: {
        title: `Fornecedores`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "suppliers",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/suppliers/edit/:id",
      component: importViews("suppliers/EditSupplier"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Editar Fornecedor`;
          return `Fornecedor ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "suppliers",
        key: "path",
      },
    },
    {
      name: "create",
      path: "/suppliers/create",
      component: importViews("suppliers/EditSupplier"),
      meta: {
        title: `Fornecedor`,
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "suppliers",
        key: "path",
        icon: "",
      },
    },
    {
      name: "view",
      path: "/suppliers/view/:id",
      component: importViews("suppliers/EditSupplier"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Detalhes do Fornecedor`;
          return `Fornecedor ${route.params.id}`;
        },
        icon: "eye",
        showTitle: true,
        requiresAuth: true,
        slug: "list",
        module: "suppliers",
        key: "path",
      },
    },
  ],
  vouchers = [
    {
      path: "/vouchers/list",
      component: importViews("vouchers/ListVouchers"),
      meta: {
        title: `Vouchers`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "vouchers",
        key: "path",
      },
    },
    {
      path: "/vouchers/reports/emissions",
      component: importViews("vouchers/reports/VouchersEmissions"),
      meta: {
        title: `Relatório de Emissões - Vouchers`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "emissions",
        module: "vouchers",
        key: "path",
      },
    },
  ],
  customers = [
    {
      path: "/customers/list",
      component: importViews("customers/ListCustomers"),
      meta: {
        title: `Clientes`,
        showTitle: true,
        icon: customersImg,
        requiresAuth: true,
        slug: "list",
        module: "customers",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/customers/edit/:id",
      component: importViews("customers/CustomerPage"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Cliente`;
          return `Cliente ${route.params.id}`;
        },

        showTitle: true,
        requiresAuth: true,
        slug: "edit2",
        icon: "edit",
        module: "customers",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/customers/edit2/:id",
      component: importViews("customers/EditCustomer"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Cliente`;
          return `Cliente ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "customers",
        key: "path",
      },
    },
  ],
  opportinities = [
    {
      path: "/opportunity/list",
      redirect: "/pipeline",
    },
    {
      name: "edit",
      path: "/opportunity/edit/:id",
      component: importViews("pipeline/OpportunityPage"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Oportunidade`;
          return `Oport. ${route.params.id}`;
        },
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        icon: "edit",
        module: "opportunity",
        key: "path",
      },
    },
  ],
  marketing = [
    {
      path: "/marketing/livelo",
      component: importViews("contracts/subpages/ContractLiveloListV2"),
      meta: {
        title: `Livelo`,
        showTitle: true,
        icon: mktImg,
        requiresAuth: true,
        slug: "livelo",
        module: "marketing",
        key: "path",
      },
    },

    {
      path: "/marketing/sms",
      component: importViews("marketing/SMS"),
      meta: {
        title: `SMS`,
        showTitle: true,
        icon: mktImg,
        requiresAuth: true,
        slug: "sms",
        module: "marketing",
        key: "path",
      },
    },
    {
      path: "/marketing/reports/campaigns",
      component: importViews("marketing/Campaigns"),
      meta: {
        title: `Campanhas`,
        showTitle: true,
        icon: mktImg,
        requiresAuth: true,
        slug: "campaigns",
        module: "Campanhas",
        key: "path",
      },
    },
  ],
  users = [
    {
      path: "/users/list",
      component: importViews("users/ListUsers"),
      meta: {
        title: `Usuários`,
        showTitle: true,
        icon: "user",
        requiresAuth: true,
        slug: "list",
        module: "users",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/users/edit/:id",
      component: importViews("users/UserPage"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Editar Usuário`;
          return `Usuário ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "users",
        key: "path",
      },
    },
    {
      name: "view",
      path: "/users/view/:id",
      component: importViews("users/ViewUser"),
      meta: {
        title(route) {
          document.title = ` ${route.params.id} - Detalhes do Usuário`;
          return `Usuário ${route.params.id}`;
        },
        icon: "eye",
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "users",
        key: "path",
      },
    },
  ],
  ProductSuppliers = [
    {
      path: "/product-suppliers/list",
      component: importViews("product-suppliers/ListProductSuppliers"),
      meta: {
        title: `Fornecedores de Produtos`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "product-suppliers",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/product-suppliers/edit/:id",
      component: importViews("product-suppliers/EditProductSupplier"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Cliente`;
          return `Cliente ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "product-suppliers",
        key: "path",
      },
    },
  ],
  airlines = [
    {
      path: "/airlines/list",
      component: importViews("airlines/ListAirlines"),
      meta: {
        title: `Cias Aéreas`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "airlines",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/airlines/edit/:id",
      component: importViews("airlines/EditAirline"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Cia`;
          return `Cia ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "airlines",
        key: "path",
      },
    },
  ],
  companies = [
    {
      path: "/companies/list",
      component: importViews("companies/ListCompanies"),
      meta: {
        title: `Empresas`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "companies",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/companies/edit/:id",
      component: importViews("companies/EditCompany"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Empresa`;
          return `Empresa ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "companies",
        key: "path",
      },
    },
  ],
  documents = [
    {
      path: "/documents/list",
      component: importViews("documents/ListDocumentsV2"),
      meta: {
        title: `Documentos`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "documents",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/documents/edit/:id",
      component: importViews("documents/EditDocumentPage"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Documento`;
          return `Documento ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "documents",
        key: "path",
      },
    },
    {
      name: "view",
      path: "/documents/view/:id",
      component: importViews("documents/ViewDocument"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Ver Documento`;
          return `Documento ${route.params.id}`;
        },
        icon: "eye",
        showTitle: true,
        requiresAuth: true,
        slug: "view",
        module: "documents",
        key: "path",
      },
    },
    {
      name: "edit2",
      path: "/documents/edit2/:id",
      component: importViews("documents/EditDocument"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Documento`;
          return `Documento ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit2",
        module: "documents",
        key: "path",
      },
    },
  ],
  cardMachines = [
    {
      path: "/card-machines/list",
      component: importViews("card-machines/ListCardMachines"),
      meta: {
        title: `PDQs`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "card-machines",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/card-machines/edit/:id",
      component: importViews("card-machines/EditCardMachine"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar PDQ`;
          return `PDQ ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "card-machines",
        key: "path",
      },
    },
  ],
  billingTicket = [
    {
      path: "/billing-tickets/list",
      component: importViews("billing-tickets/ListBillingTickets"),
      meta: {
        title: `Boletos`,
        showTitle: true,
        icon: "unordered-list",
        requiresAuth: true,
        slug: "list",
        module: "billing-tickets",
        key: "path",
      },
    },
    {
      name: "edit",
      path: "/billing-tickets/edit/:id",
      component: importViews("billing-tickets/EditBillingTicket"),
      meta: {
        title(route) {
          document.title = `${route.params.id} - Editar Boleto`;
          return `Boleto ${route.params.id}`;
        },
        icon: "edit",
        showTitle: true,
        requiresAuth: true,
        slug: "edit",
        module: "billing-tickets",
        key: "path",
      },
    },
  ],
  categories = [
    {
      path: "/categories/list",
      component: importViews("categories/ListCategories"),
      meta: {
        title: `Categorias do Sistema`,
        showTitle: true,
        icon: settingsImg,
        requiresAuth: true,
        slug: "list",
        module: "categories",
        key: "path",
      },
    },
  ],
  settings = [
    {
      path: "/settings/notifications",
      component: importViews("notifications/sendNotifications"),
      meta: {
        title: `Notificações`,
        showTitle: true,
        icon: mktImg,
        requiresAuth: true,
        slug: "notifications",
        module: "settings",
        key: "path",
      },
    },
    {
      path: "/settings/user-permissions",
      component: importViews("settings/UserPermissions"),
      meta: {
        title: `Permissões de Usuários`,
        showTitle: true,
        icon: settingsImg,
        requiresAuth: true,
        slug: "user-permissions",
        module: "settings",
        key: "path",
      },
    },
    {
      path: "/settings/bank-accounts",
      component: importViews("company-bank-accounts/ListBankAccounts"),
      meta: {
        title: `Contas Bancárias`,
        showTitle: true,
        icon: "bank",
        requiresAuth: true,
        slug: "bank-accounts",
        module: "settings",
        key: "path",
      },
    },
    {
      path: "/settings/company-credit-cards",
      component: importViews("company-credit-cards/ListCompanyCreditCards"),
      meta: {
        title: `Cartão de Crédito Corporativo`,
        showTitle: true,
        icon: "credit-card",
        requiresAuth: true,
        slug: "company-credit-cards",
        module: "settings",
        key: "path",
      },
    },
  ],
  profile = [
    {
      path: "/profile",
      component: importViews("users/Profile"),
      meta: {
        title: `Meu Perfil`,
        showTitle: true,
        icon: "user",
        requiresAuth: true,
        slug: "profile",
        module: "users",
        key: "path",
      },
    },
  ],
  chat = [
    {
      path: "/chat",
      component: importViews("chat/ChatPage"),
      meta: {
        title: `Chat`,
        showTitle: true,
        icon: "user",
        requiresAuth: true,
        slug: "chat",
        module: "chat",
        key: "path",
      },
    },
  ];

const routes = [
  {
    path: "/",
    component: SystemTabContent,
    children: [
      ...RouterTabRoutes,
      ...contracts,
      ...products,
      ...financial,
      ...creditLetters,
      ...pipeline,
      ...email,
      ...sales,
      ...events,
      ...miles,
      ...vouchers,
      ...milesOrders,
      ...customers,
      ...suppliers,
      ...marketing,
      ...ProductSuppliers,
      ...airlines,
      ...categories,
      ...companies,
      ...documents,
      ...billingTicket,
      ...cardMachines,
      ...users,
      ...settings,
      ...profile,
      ...documentations,
      ...chat,
      ...opportinities,
      {
        name: "dashboard",
        path: "/",
        component: importViews("Dashboard"),
        meta: {
          title: "Dashboard",
          icon: "",
          closable: false,
          draggable: false,
          tabClass: "dashboard-item",
        },
      },
    ],
    meta: {
      title: `${company} Omnichannel`,
      requiresAuth: true,
      module: "dashboard",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: `Login`,
      slug: "login",
    },
  },
  {
    path: "/forgot-password",
    name: "Login",
    component: Login,
    meta: {
      title: `Recuperar senha`,
      slug: "forgot-password",
    },
  },
  {
    path: "/key",
    name: "Login",
    component: Login,
    meta: {
      title: `Resetar senha`,
      slug: "key",
    },
    children: [
      {
        path: ":hash",
        component: Login,
        meta: {
          title: `Resetar senha`,
          slug: "key",
        },
      },
    ],
  },
  {
    path: "*",
    name: "Login",
    component: Login,
    meta: {
      title: `Página Não Encontrada`,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
