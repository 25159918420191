<template>
  <a class="top-menu">
    <a-avatar
      class="mr-10"
      size="large"
      icon="user"
      v-if="$store.state.userData.avatar"
      :src="$store.state.userData.avatar"
    />

    <div class="username relative">
      <div class="hello">Olá {{ $store.state.userData.first_name }}!</div>

      <div class="renew">
        <a-icon type="clock-circle" />
        Renova em: {{ $store.state.tokenExperation | formatTimeToDate }}
      </div>
    </div>

    <div class="nav">
      <ul>
        <div v-for="(item, index) in topMenu" :key="index">
          <li
            v-if="item.show === true"
            :class="`${item.class} ${
              $route.meta.module == item.url ? ' active' : ''
            }  ${item.disabled ? ' disabled' : ''} ${
              item.subMenu.length ? 'is-sub' : ''
            }`"
          >
            <div
              :class="item.url ? '' : 'disable-click'"
              class="item"
              @click="$router.push(item.url)"
            >
              <a-icon
                class="arrow-left"
                v-if="item.subMenu.length > 0"
                type="left"
              />
              {{ item.itemName }}
            </div>

            <div class="submenu" v-if="item.subMenu.length > 0">
              <ul>
                <div v-for="(item, index) in item.subMenu" :key="index">
                  <li
                    v-if="item.show === true"
                    :class="`${item.class} ${
                      $route.meta.module == item.url ? ' active' : ''
                    }  ${item.disabled ? ' disabled' : ''}  ${
                      item.subSubMenu.length ? 'is-sub' : ''
                    }`"
                  >
                    <div
                      :class="item.url ? '' : 'disable-click'"
                      class="item"
                      @click="$router.push(item.url)"
                    >
                      <a-icon
                        class="arrow-left"
                        v-if="item.subSubMenu.length > 0"
                        type="left"
                      />
                      {{ item.itemName }}
                    </div>

                    <div class="submenu sub" v-if="item.subSubMenu.length > 0">
                      <ul>
                        <li
                          v-for="(item, index) in item.subSubMenu"
                          :key="index"
                          :class="`${item.class} ${
                            $route.meta.module == item.url ? ' active' : ''
                          }  ${item.disabled ? ' disabled' : ''}`"
                          @click="$router.push(item.url)"
                        >
                          <div
                            v-if="item.show === true"
                            :class="item.url ? '' : 'disable-click'"
                            class="item"
                          >
                            {{ item.itemName }}

                            <span v-if="item.subSubMenu != undefined">
                              <a-icon
                                v-if="item.subSubMenu.length > 0"
                                type="right"
                              />
                            </span>
                          </div>

                          <div v-if="item.subSubMenu != undefined">
                            <NavigationSubMenu
                              class="submenu subsub"
                              v-if="item.subSubMenu.length > 0"
                              :submenu="item.subSubMenu"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </div>
        <div>
          <li><div class="item" @click="logOut()">Sair</div></li>
        </div>
      </ul>
    </div>
  </a>
</template>

<script>
import { format } from "date-fns";
import headerMixins from "@/components/general/header/mixins/headerMixins";
import NavigationSubMenu from "./NavigationSubMenu.vue";

export default {
  name: "NavigationAvatarMenu",
  components: { NavigationSubMenu },
  mixins: [headerMixins],
  data() {
    return {
      topMenu: [
        {
          itemName: "Meu perfil",
          icoImg: "",
          url: "/profile",
          class: "",
          show: true,
          disabled: false,
          subMenu: [],
        },
        {
          itemName: "Administrativo",
          icoImg: "",
          url: false,
          class: "",
          show: this.$root.isAdmin(),
          disabled:
            this.$store.state.userData.id == 46 ||
            this.$store.state.userData.id == 45 ||
            this.$store.state.userData.id == 44
              ? true
              : false,
          openTab: true,
          subMenu: [
            {
              itemName: "Usuários",
              url: "/users/list",
              disabled: false,
              show: true,
              openTab: true,
              class: "",
              subSubMenu: [],
            },

            {
              itemName: "Empresa",
              url: false,
              disabled: false,
              show: true,
              openTab: true,
              class: "",
              subSubMenu: [
                {
                  itemName: "Empresas / Filiais",
                  url: "/companies/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Contas Bancárias",
                  url: "/settings/bank-accounts",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Cartões de Crédito",
                  url: "/settings/company-credit-cards",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Documentos",
                  url: "/documents/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Documentações (Dev)",
                  url: "/documentations/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "PDQs",
                  url: "/card-machines/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Boletos",
                  url: "/billing-tickets/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
              ],
            },
            {
              itemName: "Produtos",
              url: false,
              disabled: false,
              show: true,
              openTab: true,
              class: "",
              subSubMenu: [
                {
                  itemName: "Fornecedores",
                  url: "/product-suppliers/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Cias Aéreas",
                  url: "/airlines/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Categorias",
                  url: "/categories/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
              ],
            },
            {
              itemName: "Configurações",
              url: false,
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [
                {
                  itemName: "Permissões",
                  url: "/settings/user-permissions",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  filters: {
    formatTimeToDate(date) {
      return format(new Date(parseInt(date)), "dd/MM/yyyy HH:mm");
    },
  },
};
</script>

<style lang="sass" scoped>

.top-menu
  .username
    width: 180px
    display: inline-block
    .hello
      top: -4px
      position: relative
  .renew
    font-size: 10px
    display: block
    line-height: 10px
    position: absolute
    left: 1px
    top: 38px
    color: #aaa
  &:hover
    .nav
      display: block
  .nav
    display: none
    position: absolute
    right: 5px
    left: 5px
    top: 60px
    background: #fff
    padding: 6px 0
    border-radius: 6px
    border: 1px solid #ddd
    box-shadow: 0 7px 10px  rgba(0, 0, 0, 0.1)
    ul
      margin: 0
      padding: 0
      list-style: none
      line-height: 1
      li.disabled
        opacity: 0.5
      li
        .arrow-left
          position: absolute
          left: 10px
          opacity: 0.3

        .submenu
          display: none
          position: absolute
          z-index: 999
          left: -201px
          padding: 6px 0
          border-radius: 6px
          top: 0
          width: 200px
          background: #fff
          border: 1px solid #ddd
          box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1)
          ul
            li
              position: relative
            li.is-sub:hover
              .submenu.sub
                display: block !important
        .item
          display: block
          padding: 10px 0
          border-bottom: 1px solid rgba(0, 0, 0, 0.1)
          transition: 0.3s
          font-size: 12px
          color: #666
          line-height: 1
          text-align: center
        .item:hover
          background: #fafafa
        .submenu.sub
            display: none !important
      li.is-sub:hover
        .submenu
          display: block
</style>
