<template>
  <aLayoutSider style="background: #434a53">
    <aRow type="flex" justify="space-between">
      <aCol class="a-center" :span="24">
        <span @click="$router.push(`/`)">
          <img
            class="logo c-pointer"
            alt="Haya"
            src="@/assets/images/menu/haya-ico.png"
          />
        </span>
      </aCol>

      <aCol :span="24">
        <div class="nav">
          <ul>
            <div v-for="(item, index) in systemMenu" :key="index">
              <li
                v-if="item.show === true"
                :class="`${item.class} ${
                  $route.meta.module == item.class ? ' active' : ''
                } ${
                  item.class == 'admin' || item.class == 'miles-cards'
                    ? ' up'
                    : ''
                }  ${item.disabled ? ' disabled' : ''}`"
              >
                <div
                  :class="item.url ? '' : 'disable-click'"
                  @click="$router.push(item.url)"
                >
                  <div class="img">
                    <img :alt="item.itemName" :src="item.icoImg" />
                  </div>

                  <div class="name">
                    {{ item.itemName }}
                  </div>
                </div>

                <div class="submenu" v-if="item.subMenu.length > 0">
                  <ul>
                    <div v-for="(item, index) in item.subMenu" :key="index">
                      <li
                        v-if="item.show === true"
                        :class="`${item.class} ${
                          $route.meta.module == item.url ? ' active' : ''
                        }  ${item.disabled ? ' disabled' : ''}`"
                      >
                        <div
                          :class="item.url ? '' : 'disable-click'"
                          class="item"
                          @click="$router.push(item.url)"
                        >
                          {{ item.itemName }}
                          <a-icon
                            v-if="item.subSubMenu.length > 0"
                            type="right"
                          />
                        </div>

                        <div
                          class="submenu sub"
                          v-if="item.subSubMenu.length > 0"
                        >
                          <ul>
                            <li
                              v-for="(item, index) in item.subSubMenu"
                              :key="index"
                              :class="`${item.class} ${
                                $route.meta.module == item.url ? ' active' : ''
                              }  ${item.disabled ? ' disabled' : ''}`"
                              @click="$router.push(item.url)"
                            >
                              <div
                                v-if="item.show === true"
                                :class="item.url ? '' : 'disable-click'"
                                class="item"
                              >
                                {{ item.itemName }}

                                <span v-if="item.subSubMenu != undefined">
                                  <a-icon
                                    v-if="item.subSubMenu.length > 0"
                                    type="right"
                                  />
                                </span>
                              </div>

                              <div v-if="item.subSubMenu != undefined">
                                <NavigationSubMenu
                                  class="submenu subsub"
                                  v-if="item.subSubMenu.length > 0"
                                  :submenu="item.subSubMenu"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </aCol>
    </aRow>
  </aLayoutSider>
</template>

<script>
import dashboardImg from "@/assets/images/menu/dashboard.png";
import productImg from "@/assets/images/menu/product.png";
import pipelineImg from "@/assets/images/menu/pipeline.png";
import calendarImg from "@/assets/images/menu/calendar.png";
import financesImg from "@/assets/images/menu/finances.png";
import contractsImg from "@/assets/images/menu/contracts.png";
import salesImg from "@/assets/images/menu/sales.png";
import mktImg from "@/assets/images/menu/marketing.png";
import milesImg from "@/assets/images/menu/miles.png";
import customersImg from "@/assets/images/menu/customers.png";
//import adminImg from "@/assets/images/menu/admin.png";
import emailImg from "@/assets/images/menu/email.png";

import NavigationSubMenu from "./nav/NavigationSubMenu.vue";
financesImg;
export default {
  name: "DesktopNavigation",
  components: { NavigationSubMenu },
  mixins: [],
  data() {
    return {
      systemMenu: [
        {
          itemName: "Pipeline",
          icoImg: pipelineImg,
          url: "/pipeline",
          class: "pipeline",
          show: true,
          openTab: true,
          disabled: false,
          subMenu: [
            {
              itemName: "Funis e etapas",
              url: "/pipeline/funnels-and-steps",
              disabled: this.$root.isAdmin() ? false : true,
              class: "",
              show: this.$root.isAdmin() ? true : false,
              openTab: true,
              subSubMenu: [],
            },
          ],
        },
        {
          itemName: "Contratos",
          icoImg: contractsImg,
          url: "/contracts/list",
          class: "contracts",
          disabled: false,
          show:
            this.$root.isAnalyst() ||
            this.$root.isAdmin() ||
            this.$root.isManager() ||
            this.$root.isMilesManager() ||
            this.$root.isUser()
              ? true
              : false,
          openTab: true,
          subMenu: [
            {
              itemName: "Relatórios",
              url: false,
              disabled: false,
              class: "",
              show: true,
              subSubMenu: [
                {
                  itemName: "Ranking de Produtos",
                  url: "/contracts/report/products",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Ranking de Produtividade",
                  url: "/contracts/report/productivity",
                  disabled:
                    this.$root.isAdmin() || this.$root.isMilesManager()
                      ? false
                      : true,
                  class: "",
                  show: this.$root.isAdmin() ? true : false,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Ranking de Fornecedores",
                  url: "/contracts/report/suppliers",
                  disabled:
                    this.$store.state.userData.id == 46 ||
                    this.$store.state.userData.id == 45 ||
                    this.$store.state.userData.id == 44
                      ? true
                      : false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Embarque",
                  url: "/contracts/report/departures",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Origem do cliente",
                  url: "/contracts/report/customer-origin",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Pagamentos",
                  url: "/contracts/report/payments",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
              ],
            },

            {
              itemName: "Financeiro",
              url: "/contracts/finances/list",
              disabled: true,
              class: "",
              show: false,
              openTab: true,
              subSubMenu: [],
            },
          ],
        },
        {
          itemName: "Vendas",
          icoImg: salesImg,
          url: "/sales/list",
          class: "sales",
          disabled:
            this.$store.state.userData.id == 46 ||
            this.$store.state.userData.id == 45 ||
            this.$store.state.userData.id == 44
              ? true
              : false,
          show: true,
          openTab: true,
          subMenu: [
            {
              itemName: "Bancos",
              url: "/sales/finances/list",
              disabled: true,
              class: "",
              show: false,
              openTab: true,
              subSubMenu: [],
            },

            {
              itemName: "Cartas de Crédito",
              url: "/credit-letters/list",
              disabled: this.$root.isAdmin() ? false : true,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },

            {
              itemName: "Contas a Receber",
              url: "/sales/report/bills-to-receive",
              disabled: false,
              class: "",
              show:
                this.$root.isAdmin() ||
                this.$root.isManager() ||
                this.$root.isMilesManager()
                  ? true
                  : false,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Lucratividade",
              url: "/sales/finances/list",
              disabled: true,
              class: "",
              show: false,
              openTab: true,
              subSubMenu: [],
            },
          ],
        },
        {
          itemName: "E-mails",
          icoImg: emailImg,
          url: "/emails",
          class: "emails",
          show:
            this.$store.state.userData.id == 1 ||
            this.$store.state.userData.id == 2
              ? true
              : false,
          openTab: true,
          disabled: false,
          subMenu: [],
        },
        {
          itemName: "Financeiro",
          icoImg: financesImg,
          url: "/financial/generate-invoices",
          class: "financial",
          show:
            this.$store.state.userData.id == 1 ||
            this.$store.state.userData.id == 2 ||
            this.$store.state.userData.id == 7 ||
            this.$store.state.userData.id == 35 ||
            this.$store.state.userData.id == 28 ||
            this.$store.state.userData.id == 37 ||
            this.$store.state.userData.id == 29 ||
            this.$root.isFinancial()
              ? true
              : false,
          openTab: true,
          disabled: false,
          subMenu: [
            {
              itemName: "Bancos",
              url: "/financial/banks",
              disabled: false,
              class: "",
              show: this.$root.isAdmin(),
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Gerar Faturas",
              url: "/financial/generate-invoices",
              disabled: false,
              class: "",
              show: this.$root.isAdmin(),
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Faturas",
              url: "/financial/invoices",
              disabled: false,
              class: "",
              show: this.$root.isAdmin(),
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Relatórios",
              url: false,
              disabled: false,
              class: "",
              show: true,
              subSubMenu: [
                {
                  itemName: "A Pagar (Embarque)",
                  url: "/contracts/report/departures-to-pay",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
              ],
            },
          ],
        },
        {
          itemName: "Calendário",
          icoImg: calendarImg,
          url: "/events/calendar",
          class: "events",
          show:
            this.$store.state.userData.id == 1 ||
            this.$store.state.userData.id == 2 ||
            this.$store.state.userData.id == 7 ||
            this.$store.state.userData.id == 35 ||
            this.$store.state.userData.id == 28 ||
            this.$store.state.userData.id == 37 ||
            this.$store.state.userData.id == 29 ||
            this.$root.isUser()
              ? true
              : false,
          openTab: true,
          disabled: false,
          subMenu: [],
        },
        {
          itemName: "Milhas",
          icoImg: milesImg,
          url: false,
          class: "miles-cards",
          show:
            this.$root.isAdmin() ||
            this.$root.isManager() ||
            this.$root.isMilesManager()
              ? true
              : false,
          disabled:
            this.$store.state.userData.id == 46 ||
            this.$store.state.userData.id == 45 ||
            this.$store.state.userData.id == 44
              ? true
              : false,
          openTab: true,
          subMenu: [
            {
              itemName: "Banco de Milhas",
              url: "/miles-cards/list",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Fornecedores",
              url: "/suppliers/list",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Solicitações",
              url: "/miles-orders/list",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Comprar",
              url: "/buy-miles",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Pagamentos",
              url: "/miles-finances/payments",
              disabled: false,
              class: "",
              show:
                this.$root.isAdmin() || this.$root.isMilesManager()
                  ? true
                  : false,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Relatórios",
              url: false,
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [
                {
                  itemName: "Milhas",
                  url: "/miles-cards/reports/miles",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Emissões",
                  url: "/miles-cards/reports/emissions",
                  disabled: this.$root.isAdmin() ? false : true,
                  class: "",
                  show: this.$root.isAdmin() ? true : false,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Bloqueios",
                  url: "/miles-cards/reports/blocks",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Compras",
                  url: "/miles-cards/reports/purchases",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Taxas Cartão",
                  url: "/miles-cards/reports/card-taxes",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Reembolso",
                  url: "/miles-cards/reports/refund",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
              ],
            },
            {
              itemName: "Vouchers",
              url: false,
              disabled: false,
              show: this.$root.isAdmin() || this.$root.isMilesManager(),
              class: "",
              subSubMenu: [
                {
                  itemName: "Lista Vouchers",
                  url: "/vouchers/list",
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                },
                {
                  itemName: "Relatórios",
                  url: false,
                  disabled: false,
                  class: "",
                  show: true,
                  openTab: true,
                  subSubMenu: [
                    {
                      itemName: "Emissões",
                      url: "/vouchers/reports/emissions",
                      disabled: false,
                      class: "",
                      show: true,
                      openTab: true,
                      subSubMenu: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          itemName: "Produtos",
          icoImg: productImg,
          url: "/products/list",
          class: "products",
          show: true,
          openTab: true,
          disabled: false,
          subMenu: [
            {
              itemName: "Cadastrar Produto",
              url: "/products/new",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Orçamentos de Produtos",
              url: "/products/budget",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
          ],
        },
        {
          itemName: "Clientes",
          icoImg: customersImg,
          url: "/customers/list",
          class: "customers",
          module: "customers",
          show: true,
          openTab: true,
          subMenu: [],
        },
        {
          itemName: "Marketing",
          icoImg: mktImg,
          url: false,
          class: "marketing up",
          show: this.$root.isAdmin() || this.$root.isAnalyst(),
          openTab: true,
          subMenu: [
            {
              itemName: "Notificações",
              url: "/settings/notifications",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Livelo",
              url: "/marketing/livelo",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "SMS",
              url: "/marketing/sms",
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [],
            },
            {
              itemName: "Relatórios",
              url: false,
              disabled: false,
              class: "",
              show: true,
              openTab: true,
              subSubMenu: [
                {
                  itemName: "Ranking de Produtos",
                  url: "/contracts/report/products",
                  disabled: false,
                  class: "marketing",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
                {
                  itemName: "Campanhas",
                  url: "/marketing/reports/campaigns",
                  disabled: false,
                  class: "marketing",
                  show: true,
                  openTab: true,
                  subSubMenu: [],
                },
              ],
            },
          ],
        },
      ],
      systemNavItems: [
        {
          itemName: "Dashboard",
          icoImg: dashboardImg,
          url: "/",
          class: "dashboard",
          show: true,
          openTab: true,
          disabled: false,
          subMenu: [],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.disable-click
  cursor: default

aside.ant-layout-sider
  position: relative
  z-index: 101

.nav
  float: left
  width: 100%
  color: #fff
  ul
    padding: 0
    margin: 0
    list-style: none
    li
      position: relative
      margin-bottom: 1px
      display: block
      padding: 10px 0
      line-height: 1
      opacity: 1
      transition: .3s
      cursor: pointer
      text-align: center
      height: auto
      text-align: center
      max-height: 88px
      transition: .3s
      &.active
        background: #303842
      &.up
        .submenu
          top: -50px
        .sub.submenu
          top: unset !important
          bottom: -7px
      img
        opacity: 0.5
        height: 26px
      .submenu
        display: none
        position: absolute
        z-index: 999
        right: -201px
        padding: 6px 0
        border-radius: 6px
        top: 0
        width: 200px
        background: #434A53
        ul
          padding: 0
          li
            position: relative
            padding: 0
            .submenu
              ul li:hover
                .submenu
                  top: unset
                  bottom: -7px
                  display: block

            .anticon-right
              position: absolute
              right: 10px
              opacity: 0.3
            .subsub
              display: none
            .submenu.sub
              display: none
            .item
              display: block
              padding: 10px 0
              border-bottom: 1px solid rgba(255,255,255,0.05)
              transition: .3s
              font-size: 12px
              color: #AAB2BC
              line-height: 1
            &.disabled
              opacity: 0.4
              pointer-events: none
            &:hover
              background: #303842
              .submenu.sub
                display: block
      &.disabled
        opacity: 0.4
        pointer-events: none
      &.router-link-active
        background: #303842
      &:hover
        background: #303842
        opacity: 1
        img
          opacity: 1
        .name
          color: #fff

        .submenu
          display: block
      .img
        margin-bottom: 11px
      .name
        font-size: 11px
        color: #AAB2BC
        line-height: 1

@media only screen and (max-width: 1600px)
  .nav
    ul
      li
        padding: 10px 0
        img
          height: 20px
        .submenu
          ul
            li
              .anticon-right
                position: absolute
                right: 10px
                opacity: 0.3
              .submenu.sub
                display: none
              .item
                display: block
                padding: 10px 0
                border-bottom: 1px solid rgba(255,255,255,0.05)
                transition: .3s
                font-size: 12px
                color: #AAB2BC
                line-height: 1
              &.disabled
                opacity: 0.4
                pointer-events: none
              &:hover
                background: #303842
                .submenu.sub
                  display: block
            //   background: red !important
            //   border-radius: 5px 5px 0 0
        &.disabled
          opacity: 0.4
          pointer-events: none
        &.router-link-active
          background: #303842
        &:hover
          background: #303842
          opacity: 1
          img
            opacity: 1
          .name
            color: #fff
          .submenu
            display: block
        .img
          margin-bottom: 8px
        .name
          font-size: 10px
</style>
