import Vue from "vue";
import App from "./App.vue";

import "./plugins/axios";
import "ant-design-vue/dist/antd.less";
import "vue-file-agent/dist/vue-file-agent.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "vue-router-tab/dist/lib/vue-router-tab.css";

import RouterTab from "vue-router-tab";
import router from "./router";
import store from "./store/index";
import Antd from "ant-design-vue";
import Vuex from "vuex";
import VueTheMask from "vue-the-mask";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueCurrencyInput from "vue-currency-input";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";

import mDatePicker from "vue-multi-date-picker";
import money from "v-money";
import VueFileAgent from "vue-file-agent";
import JsonExcel from "vue-json-excel";
import HighchartsVue from "highcharts-vue";
import JsonViewer from "vue-json-viewer";
import vueKanban from "vue-kanban";
import { uniLayerGroupMonochrome, uniCarWash } from "vue-unicons/dist/icons";

Vue.use(RouterTab);
Vue.use(JsonViewer);
Vue.use(vueKanban);
Vue.use(HighchartsVue);
Vue.use(VueFileAgent);
Vue.use(mDatePicker);
Vue.use(Vuex);
Vue.use(Antd);
Vue.use(VueTheMask);
Vue.use(VueSmoothScroll);
Vue.component("downloadExcel", JsonExcel);
Vue.use(Unicon);
Vue.use(money, {
  precision: 2,
  prefix: "R$ ",
});

Unicon.add([uniLayerGroupMonochrome, uniCarWash]);

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: "BRL",
  },
});

Vue.filter("formatMoney", (value) => {
  if (value) {
    let val = parseFloat(value).toFixed(2).replace(".", ",");
    return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  } else {
    return `R$ 0,00`;
  }
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  data: {
    token: store.state.token,
    isLoggedIn: store.state.isLoggedIn,
    windowWidth: window.innerWidth,
    userData: {},
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  methods: {
    resizeWindow() {
      this.windowWidth = window.innerWidth;
    },
    myLog(code, time) {
      return this.$message.info(code, time);
    },
    isAdmin() {
      return this.$store.state.userData.role === "admin";
    },
    isAnalyst() {
      return this.$store.state.userData.role === "analyst";
    },
    isManager() {
      return this.$store.state.userData.role === "manager";
    },
    isUser() {
      return (
        this.$store.state.userData.role === "user" ||
        this.$store.state.userData.id == 25 ||
        this.$store.state.userData.id == 33
      );
    },
    isMilesManager() {
      return (
        this.$store.state.userData.role === "miles-manager" ||
        this.$store.state.userData.id == 30
      );
    },
    isFinancial() {
      return this.$store.state.userData.role === "financial";
    },
    checkCapabilities(capabilities, module, allow) {
      let flag = false;

      capabilities.forEach((section) => {
        if (section.module == module) {
          section.roles.forEach((role) => {
            if (role.slug == this.$store.state.userData.role) {
              if (role.capabilitiesList.includes(allow)) {
                flag = true;
              }
            }
          });
        }
      });
      return flag;
    },
    reponsive(windowSizes) {
      let theSize = 24;

      windowSizes.forEach((size) => {
        if (size.min <= this.windowWidth && size.max >= this.windowWidth) {
          theSize = size.span;
        }
      });
      return theSize;
    },
    responsive(windowSizes) {
      let theSize = 24;
      windowSizes.forEach((size) => {
        if (size.min <= this.windowWidth && size.max >= this.windowWidth) {
          theSize = size.span;
        }
      });
      return theSize;
    },
  },
  render: (h) => h(App),
}).$mount("#app");
