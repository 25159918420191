var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),(!_vm.isLoginSuccessful)?[_c('aForm',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aFormItem',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido.',
                },
                {
                  required: true,
                  message: 'Escreva seu e-mail no campo.',
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: 'Escreva um e-mail válido.',\n                },\n                {\n                  required: true,\n                  message: 'Escreva seu e-mail no campo.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"size":"large","placeholder":"Insira seu e-mail"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('aFormItem',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Escreva sua senha.',
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Escreva sua senha.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"size":"large","type":"password","placeholder":"Insira sua senha"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('aFormItem',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remember',
            {
              valuePropName: 'checked',
              initialValue: true,
            },
          ]),expression:"[\n            'remember',\n            {\n              valuePropName: 'checked',\n              initialValue: true,\n            },\n          ]"}]},[_vm._v("\n          Lembrar\n        ")]),_vm._v(" "),_c('a',{staticClass:"login-form-forgot",on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v("\n          Esqueceu sua senha?\n        ")])],1),_vm._v(" "),_c('aFormItem',[_c('a-button',{attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v("\n          Login\n        ")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.isLoginSuccessful)?[_c('AuthenticatorForm',{attrs:{"twoFactorsIsEnabled":_vm.twoFactorsIsEnabled,"userEmail":_vm.userEmail,"updateStorageData":true,"isLoginPage":true}})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-60 a-center"},[_c('img',{attrs:{"src":require("@/assets/images/haya-logo-blk.png"),"alt":"logo"}})])
}]

export { render, staticRenderFns }