<template>
  <div>
    <template v-if="twoFactorsIsEnabled">
      <aFormModel :model="codeForm" @submit="onSubmitCodeForm">
        <aRow :gutter="10">
          <aCol :span="24">
            <aAlert
              class="f12 mb-20"
              message="Insira o código do seu app de autenticação abaixo."
              type="info"
              banner
              :show-icon="false"
            />
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center one"
                ref="one"
                v-model="codeForm.one"
                size="large"
                inputmode="numeric"
                :maxLength="1"
                @change="onChangeCode"
                @pressEnter="onSubmitCodeForm"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center two"
                ref="two"
                v-model="codeForm.two"
                inputmode="numeric"
                size="large"
                :maxLength="1"
                @change="onChangeCode"
                @pressEnter="onSubmitCodeForm"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center three"
                ref="three"
                v-model="codeForm.three"
                inputmode="numeric"
                size="large"
                :maxLength="1"
                @change="onChangeCode"
                @pressEnter="onSubmitCodeForm"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center four"
                ref="four"
                v-model="codeForm.four"
                inputmode="numeric"
                size="large"
                :maxLength="1"
                @change="onChangeCode"
                @pressEnter="onSubmitCodeForm"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center five"
                ref="five"
                v-model="codeForm.five"
                inputmode="numeric"
                size="large"
                :maxLength="1"
                @change="onChangeCode"
                @pressEnter="onSubmitCodeForm"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="4">
            <aFormModelItem>
              <aInput
                class="a-center six"
                ref="six"
                v-model="codeForm.six"
                inputmode="numeric"
                size="large"
                :maxLength="1"
                @change="onChangeCode"
              />
            </aFormModelItem>
          </aCol>
          <aCol :span="24">
            <div
              v-if="
                this.codeForm.one ||
                this.codeForm.two ||
                this.codeForm.three ||
                this.codeForm.four ||
                this.codeForm.five ||
                this.codeForm.six
              "
              class="a-right mb-10 f12"
              style="margin-top: -15px"
            >
              <a
                @click="
                  codeForm = {
                    one: '',
                    two: '',
                    three: '',
                    four: '',
                    five: '',
                    six: '',
                  };
                  $refs.one.focus();
                "
                >Limpar</a
              >
            </div>
            <aButton
              block
              size="large"
              type="primary"
              @click="onSubmitCodeForm"
              :disabled="
                codeForm.one &&
                codeForm.two &&
                codeForm.three &&
                codeForm.four &&
                codeForm.five &&
                codeForm.six
                  ? false
                  : true
              "
              :loading="loadingCodeForm"
            >
              Verificar código
            </aButton>
          </aCol>
        </aRow>
      </aFormModel>
    </template>
    <template v-if="!twoFactorsIsEnabled">
      <aAlert class="f12 mb-20" type="info" banner :show-icon="false">
        <template #message>
          Escaneie o QR Code abaixo com um aplicativo de autenticação de dois
          fatores.
          <a
            href="https://play.google.com/store/apps/details?id=com.salesforce.authenticator&hl=en&pli=1"
            target="_blank"
            >Clique aqui</a
          >.
        </template>
      </aAlert>

      <div class="a-center">
        <template v-if="!loadingQrCodeForm">
          <img :src="linkQrCode" alt="qr-code" />
        </template>

        <template v-if="loadingQrCodeForm">
          <aIcon type="loading" style="font-size: 80px; color: #ccc" />
        </template>

        <div class="mt-30">
          <aButton type="primary" @click="twoFactorsIsEnabled = true"
            >Inserir código</aButton
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AuthenticatorForm",
  props: {
    twoFactorsIsEnabled: Boolean,
    userEmail: String,
    isLoginPage: Boolean,
    updateStorageData: Boolean,
  },
  data() {
    return {
      loadingCodeForm: false,
      loadingQrCodeForm: false,
      linkQrCode: "",
      codeForm: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.one.focus();
    }, 100);

    if (!this.twoFactorsIsEnabled) this.enable2Factors();
  },
  methods: {
    enable2Factors() {
      this.loadingQrCodeForm = true;
      this.$hayaApi
        .post("/2fa/enable", {
          email: this.userEmail,
        })
        .then(({ data }) => {
          this.twoFactorsIsEnabled = false;
          this.linkQrCode = data.qrCode;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => (this.loadingQrCodeForm = false));
    },
    onSubmitCodeForm() {
      if (
        this.codeForm.one &&
        this.codeForm.two &&
        this.codeForm.three &&
        this.codeForm.four &&
        this.codeForm.five
      ) {
        this.loadingCodeForm = true;

        const verificationData = {
          email: this.userEmail,
          code: `${this.codeForm.one}${this.codeForm.two}${this.codeForm.three}${this.codeForm.four}${this.codeForm.five}${this.codeForm.six}`,
        };

        this.$hayaApi
          .post("/2fa/verify", verificationData)
          .then(({ data }) => {
            if (this.updateStorageData) {
              this.$root.token = data.jwt;
              this.$root.tokenExpiration = data.expireAt;
              localStorage.setItem("token", data.jwt);
              localStorage.setItem("isLoggedIn", data.isLoggedIn);
              localStorage.setItem("tokenExpiration", data.expireAt);
              localStorage.setItem("userData", JSON.stringify(data.userData));
            }

            this.loading = false;
            this.$message.success(data.message);
            this.$emit("verificationSuccessfully", verificationData);

            if (this.isLoginPage) {
              setTimeout(() => {
                window.open("/", "_self");
              }, 500);
            }
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
          })
          .finally(() => (this.loadingCodeForm = false));
      }
    },
    onChangeCode(e) {
      if (e.target.className.includes("one")) this.$refs.two.focus();
      if (e.target.className.includes("two")) this.$refs.three.focus();
      if (e.target.className.includes("three")) this.$refs.four.focus();
      if (e.target.className.includes("four")) this.$refs.five.focus();
      if (e.target.className.includes("five")) this.$refs.six.focus();
      if (e.target.className.includes("six")) {
        setTimeout(() => {
          this.onSubmitCodeForm();
        }, 100);
      }

      if (e.target.className.includes("one") && e.target.value === "")
        this.$refs.one.focus();
      if (e.target.className.includes("two") && e.target.value === "")
        this.$refs.one.focus();
      if (e.target.className.includes("three") && e.target.value === "")
        this.$refs.two.focus();
      if (e.target.className.includes("four") && e.target.value === "")
        this.$refs.three.focus();
      if (e.target.className.includes("five") && e.target.value === "")
        this.$refs.four.focus();
      if (e.target.className.includes("six") && e.target.value === "")
        this.$refs.five.focus();
    },
  },
};
</script>
