var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"top-menu"},[(_vm.$store.state.userData.avatar)?_c('a-avatar',{staticClass:"mr-10",attrs:{"size":"large","icon":"user","src":_vm.$store.state.userData.avatar}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"username relative"},[_c('div',{staticClass:"hello"},[_vm._v("Olá "+_vm._s(_vm.$store.state.userData.first_name)+"!")]),_vm._v(" "),_c('div',{staticClass:"renew"},[_c('a-icon',{attrs:{"type":"clock-circle"}}),_vm._v("\n      Renova em: "+_vm._s(_vm._f("formatTimeToDate")(_vm.$store.state.tokenExperation))+"\n    ")],1)]),_vm._v(" "),_c('div',{staticClass:"nav"},[_c('ul',[_vm._l((_vm.topMenu),function(item,index){return _c('div',{key:index},[(item.show === true)?_c('li',{class:`${item.class} ${
            _vm.$route.meta.module == item.url ? ' active' : ''
          }  ${item.disabled ? ' disabled' : ''} ${
            item.subMenu.length ? 'is-sub' : ''
          }`},[_c('div',{staticClass:"item",class:item.url ? '' : 'disable-click',on:{"click":function($event){return _vm.$router.push(item.url)}}},[(item.subMenu.length > 0)?_c('a-icon',{staticClass:"arrow-left",attrs:{"type":"left"}}):_vm._e(),_vm._v("\n            "+_vm._s(item.itemName)+"\n          ")],1),_vm._v(" "),(item.subMenu.length > 0)?_c('div',{staticClass:"submenu"},[_c('ul',_vm._l((item.subMenu),function(item,index){return _c('div',{key:index},[(item.show === true)?_c('li',{class:`${item.class} ${
                    _vm.$route.meta.module == item.url ? ' active' : ''
                  }  ${item.disabled ? ' disabled' : ''}  ${
                    item.subSubMenu.length ? 'is-sub' : ''
                  }`},[_c('div',{staticClass:"item",class:item.url ? '' : 'disable-click',on:{"click":function($event){return _vm.$router.push(item.url)}}},[(item.subSubMenu.length > 0)?_c('a-icon',{staticClass:"arrow-left",attrs:{"type":"left"}}):_vm._e(),_vm._v("\n                    "+_vm._s(item.itemName)+"\n                  ")],1),_vm._v(" "),(item.subSubMenu.length > 0)?_c('div',{staticClass:"submenu sub"},[_c('ul',_vm._l((item.subSubMenu),function(item,index){return _c('li',{key:index,class:`${item.class} ${
                          _vm.$route.meta.module == item.url ? ' active' : ''
                        }  ${item.disabled ? ' disabled' : ''}`,on:{"click":function($event){return _vm.$router.push(item.url)}}},[(item.show === true)?_c('div',{staticClass:"item",class:item.url ? '' : 'disable-click'},[_vm._v("\n                          "+_vm._s(item.itemName)+"\n\n                          "),(item.subSubMenu != undefined)?_c('span',[(item.subSubMenu.length > 0)?_c('a-icon',{attrs:{"type":"right"}}):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),(item.subSubMenu != undefined)?_c('div',[(item.subSubMenu.length > 0)?_c('NavigationSubMenu',{staticClass:"submenu subsub",attrs:{"submenu":item.subSubMenu}}):_vm._e()],1):_vm._e()])}),0)]):_vm._e()]):_vm._e()])}),0)]):_vm._e()]):_vm._e()])}),_vm._v(" "),_c('div',[_c('li',[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.logOut()}}},[_vm._v("Sair")])])])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }