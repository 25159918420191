import { render, staticRenderFns } from "./NavigationSubMenu.vue?vue&type=template&id=2c30d838&scoped=true&"
import script from "./NavigationSubMenu.vue?vue&type=script&lang=js&"
export * from "./NavigationSubMenu.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSubMenu.vue?vue&type=style&index=0&id=2c30d838&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c30d838",
  null
  
)

export default component.exports