var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Voltar ")],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sent),expression:"sent"}]},[_c('a-alert',{staticClass:"mt-20",attrs:{"type":"success","message":"Sucesso!","show-icon":"","banner":""}},[_c('div',{staticStyle:{"font-size":"13px"},attrs:{"slot":"description"},slot:"description"},[_vm._v("\n                Um e-mail de redefinição de senha foi enviado para o seu\n                endereço de e-mail, mas pode levar alguns minutos para\n                aparecer na sua caixa de entrada. "),_c('br'),_vm._v(" "),_c('br'),_vm._v("\n                Aguarde pelo menos 10 minutos antes de tentar novamente ou\n                verifique sua caixa de spam.\n            ")])])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sent),expression:"!sent"}]},[_c('h1',{staticClass:"mt-30"},[_vm._v("Recuperar Senha")]),_vm._v(" "),_c('a-alert',{staticClass:"mb-10 mt-10",staticStyle:{"font-size":"13px"},attrs:{"message":"Perdeu sua senha?  Digite seu endereço de e-mail. Você receberá um link por e-mail para criar uma nova senha.","type":"info","banner":""}}),_vm._v(" "),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                        rules: [
                            {
                                type: 'email',
                                message: 'Escreva um e-mail válido.',
                            },
                            {
                                required: true,
                                message: 'Escreva seu e-mail no campo.',
                            },
                        ],
                    },
                ]),expression:"[\n                    'email',\n                    {\n                        rules: [\n                            {\n                                type: 'email',\n                                message: 'Escreva um e-mail válido.',\n                            },\n                            {\n                                required: true,\n                                message: 'Escreva seu e-mail no campo.',\n                            },\n                        ],\n                    },\n                ]"}],staticClass:"travel-input",attrs:{"size":"large","placeholder":"Insira seu e-mail"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v("\n                Enviar\n            ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }