<template>
  <div>
    <div class="mb-60 a-center">
      <img src="@/assets/images/haya-logo-blk.png" alt="logo" />
    </div>
    <template v-if="!isLoginSuccessful">
      <aForm class="login-form" :form="form" @submit="handleSubmit">
        <aFormItem>
          <a-input
            class="travel-input"
            size="large"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido.',
                  },
                  {
                    required: true,
                    message: 'Escreva seu e-mail no campo.',
                  },
                ],
              },
            ]"
            placeholder="Insira seu e-mail"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </aFormItem>
        <aFormItem>
          <a-input-password
            class="travel-input"
            size="large"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Escreva sua senha.',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Insira sua senha"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </aFormItem>

        <aFormItem>
          <a-checkbox
            v-decorator="[
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true,
              },
            ]"
          >
            Lembrar
          </a-checkbox>

          <a class="login-form-forgot" @click="forgotPassword()">
            Esqueceu sua senha?
          </a>
        </aFormItem>
        <aFormItem>
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            :loading="loading"
          >
            Login
          </a-button>
        </aFormItem>
      </aForm>
    </template>
    <template v-if="isLoginSuccessful">
      <AuthenticatorForm
        :twoFactorsIsEnabled="twoFactorsIsEnabled"
        :userEmail="userEmail"
        :updateStorageData="true"
        :isLoginPage="true"
      />
    </template>
  </div>
</template>

<script>
import AuthenticatorForm from "./AuthenticatorForm.vue";

export default {
  name: "LoginForm",
  components: { AuthenticatorForm },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      twoFactorsIsEnabled: false,
      isLoginSuccessful: false,
      userEmail: "",
    };
  },
  methods: {
    userAuthentication(userData) {
      userData.is_login_page = true;

      this.$hayaApi
        .post("/login", userData)
        .then(({ data }) => {
          this.isLoginSuccessful = true;
          this.userEmail = userData.email;
          this.twoFactorsIsEnabled = data.twoFactorsIsEnabled;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    forgotPassword() {
      this.$router.push(`/forgot-password`);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.userAuthentication(values);
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
