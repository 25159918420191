import replaceOnce from "replace-once";
import { format } from "date-fns";

export default {
  data() {
    return {
      theNewestNotification: {},
      showNewestNotification: false,
      totalUnread: 0,
      loadingNotifications: false,
      theExpirationDate: "",
    };
  },
  methods: {
    expirationDate() {
      this.theExpirationDate = format(
        new Date(
          parseInt(
            localStorage.getItem("tokenExpiration")
              ? localStorage.getItem("tokenExpiration")
              : 0
          ) * 1000
        ),
        "dd/MM/yyyy HH:mm"
      );
    },
    logOut() {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("userData");
      localStorage.removeItem("loglevel:webpack-dev-server");
      localStorage.removeItem("showContractListProfitData");
      localStorage.removeItem("navItens");

      window.open("/login", "_self");
    },
    clearCache() {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("userData");
      localStorage.removeItem("loglevel:webpack-dev-server");
      localStorage.removeItem("menu");
      localStorage.removeItem("showContractListProfitData");
      localStorage.removeItem("navItens");

      const key = "updatable";

      this.$message.loading({
        content: "Limpando o cache, aguarde...",
        key,
      });

      setTimeout(() => {
        this.$message.success({
          content: "Sucesso! Você será direcionado(a) ao login.",
          key,
          duration: 2,
        });

        setTimeout(() => {
          window.open("/login", "_self");
        }, 150);
      }, 3500);
    },

    getNotifications() {
      this.$http
        .get(
          "/notification/list?page=1&per_page=20&user_id=" +
            this.$store.state.userData.id
        )
        .then(({ data }) => {
          this.totalUnread = data.meta.total_unread;
          this.showNewestNotification = false;
          this.theNewestNotification = {};
          this.loadingNotifications = false;
          if (data.data[0].is_read == 0) {
            this.showNewestNotification = true;
            this.theNewestNotification = data.data[0];
          }
        })
        .catch(({ response }) => {
          response;
          this.totalUnread = response.data.meta.total_unread;
          this.loadingNotifications = false;
        });
    },
    formatText(text) {
      let find = ["<p><br></p>", "<p>", "<img"];
      let replace = [
        "",
        "<p style='margin-bottom: 5px'>",
        "<img id='img-uploaded'",
      ];

      return replaceOnce(text, find, replace, "gi");
    },
    readNotification(id) {
      this.loadingNotifications = true;
      this.$http
        .post("/notification/update-field.php", {
          id,
          field: "is_read",
          value: "1",
        })
        .then(() => {
          this.showNewestNotification = false;
          this.theNewestNotification = {};
          this.loadingNotifications = false;

          this.getNotifications();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingNotifications = false;
        });
    },
    notificationGoToPage(action) {
      if (action && !action.includes("http")) this.$router.push(action);
      if (action && action.includes("http")) window.open(action, "_blank");
    },
  },
};
