<template>
  <div class="pd-0 bg">
    <router-tab
      style="padding: 0 !important"
      :lang="customLanguage"
      :max-alive="30"
      restore
      :page-transition="{
        name: 'router-tab-swap',
        mode: 'out-in',
      }"
    >
      <template #start>
        <div
          class="c-pointer dashboard-fixed"
          :class="$route.name === 'dashboard' ? 'active' : ''"
          @click="$router.push(`/`)"
        >
          <img
            src="@/assets/images/dashboard/tabnav/dashboard.png"
            width="13"
            class="ml-5"
          />
        </div>
      </template>
      <template #end>
        <a-row class="user-profile" type="flex" justify="end">
          <a-col>
            <a-popconfirm
              title="Tem certeza que deseja limpar o cache?"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="clearCache"
            >
              <img
                class="c-pointer"
                src="@/assets/images/dashboard/clear-cache.png"
              />
            </a-popconfirm>
          </a-col>
          <a-col>
            <a-popover
              placement="bottom"
              trigger="click"
              width="300px"
              class="notifications"
            >
              <template slot="content">
                <ListNotifications
                  v-if="!loadingNotifications"
                  @getNotifications="getNotifications"
                  :loading="loadingNotifications"
                />
              </template>
              <template slot="title">
                <a-row type="flex" justify="space-between">
                  <a-col> Notificações </a-col>

                  <a-col>
                    <span
                      class="reload-btn c-pointer"
                      @click="reloadNotifications"
                    >
                      <a-tooltip>
                        <template slot="title"> Atualizar </template>
                        <img
                          src="@/assets/images/contract/sync.png"
                          alt="reload"
                          width="14"
                        />
                      </a-tooltip>
                    </span>
                  </a-col>
                </a-row>
              </template>
              <div class="action">
                <a-badge :count="totalUnread">
                  <img src="@/assets/images/dashboard/sino.png" />
                </a-badge>
              </div>
            </a-popover>
          </a-col>
          <a-col
            style="
              padding-left: 10px;
              padding-right: 0;
              width: 252px;
              text-align: left;
            "
          >
            <NavigationAvatarMenu />
          </a-col>
        </a-row>
      </template>
      <template #default="tab">
        <template v-if="tab.closable">
          <a-icon
            v-if="imageAndIconConditional(tab.icon) === 'icon'"
            class="router-tab__item-icon"
            :type="tab.icon"
          />

          <img
            v-if="imageAndIconConditional(tab.icon) === 'image'"
            :src="tab.icon"
            class="mr-5"
            width="15"
          />

          <span class="router-tab__item-title" :title="tab.tips">
            {{ tab.title }}
          </span>

          <i
            v-if="tab.closable"
            class="router-tab__item-close"
            @click.prevent="tab.close"
          />
        </template>
      </template>
    </router-tab>

    <div v-if="showNewestNotification" id="newest-notification">
      <div class="head">
        <span class="close" @click="readNotification(theNewestNotification.id)">
          <a-icon type="close" />
        </span>
        <a-row :gutter="10">
          <a-col :span="4">
            <img
              v-if="theNewestNotification.created_by == 0"
              src="@/assets/images/haya-ico.png"
              width="35"
              style="
                position: relative;
                top: 2px;
                left: 3px;
                background: #fff;
                padding: 3px;
                border-radius: 50px;
              "
            />

            <img
              v-if="theNewestNotification.created_by != 0"
              :src="theNewestNotification.created_by.avatar"
              width="40"
              style="
                position: relative;
                top: 2px;
                left: 3px;
                background: #fff;
                padding: 3px;
                border-radius: 50px;
              "
            />
          </a-col>
          <a-col :span="20">
            <div class="txt">nova notificação</div>
            <div class="title">
              {{ theNewestNotification.title }}
            </div>
          </a-col>
        </a-row>
      </div>

      <div
        class="body"
        @click="notificationGoToPage(theNewestNotification.action)"
      >
        <div v-html="formatText(theNewestNotification.content)"></div>

        <a-row class="meta">
          <a-col :span="12">{{
            theNewestNotification.created_by == 0
              ? "Haya"
              : `${theNewestNotification.created_by.first_name} ${theNewestNotification.created_by.last_name}`
          }}</a-col>

          <a-col class="a-right" :span="12">
            {{ formatDateTime(theNewestNotification.created) }}
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import ListNotifications from "@/components/notifications/ListNotifications.vue";
import headerMixins from "@/components/general/header/mixins/headerMixins";
import formatThings from "@/mixins/general/formatThings.js";
import NavigationAvatarMenu from "../header/nav/NavigationAvatarMenu.vue";

export default {
  name: "SystemTabContent",
  components: { ListNotifications, NavigationAvatarMenu },
  mixins: [headerMixins, formatThings],
  data() {
    return {
      customLanguage: {
        tab: {
          untitled: "Sem título",
        },
        contextmenu: {
          refresh: "Recarregar aba",
          refreshAll: "Recarregar todas abas",
          close: "Fechar esta aba",
          closeLefts: "Fechar abas a esquerda",
          closeRights: "Fechar abas a direita",
          closeOthers: "Fechar outras",
        },
        msg: {
          keepLastTab: "Keep at least 1 tab",
        },
      },
    };
  },
  mounted() {
    this.getNotifications();
    this.expirationDate();
    setInterval(() => {
      this.getNotifications();
    }, 20000);
  },
  methods: {
    imageAndIconConditional(type) {
      let theType = "icon";
      if (type.includes("/img") || type.includes("image")) {
        theType = "image";
      }
      return theType;
    },
    reloadNotifications() {
      this.loadingNotifications = true;

      setTimeout(() => {
        this.getNotifications();
      }, 100);
    },
  },
};
</script>

<style lang="sass">
#newest-notification
  position: absolute
  right: 20px
  top: 80px
  background: linear-gradient( 180deg, rgb(255, 220, 235) 0%, rgb(255, 247, 223) 100%)
  z-index: 111
  width: 300px
  overflow: auto
  max-height: 550px
  box-shadow: 0 0 10px #aaa
  border-radius: 10px
  padding: 0
  .head
    position: relative
    padding: 8px 10px
    border-bottom: 1px solid #fbc6da
    line-height: 1.4
    .close
      position: absolute
      top: 3px
      cursor: pointer
      right: 8px
      z-index: 10
    .txt
      color: #be4178
      font-size: 12px
    .title
      color: #444
      font-size: 14px
      font-weight: 600
      line-height: 1
  .body
    font-size: 14px
    padding: 15px
    cursor: pointer
    .meta
      color: #aaa
      font-size: 11px
      font-weight: 600
      margin: 10px 0 -10px
  .body p
    margin-bottom: 5px !important
.router-tab__header
  position: sticky
  z-index: 41
  top: -1px
  background: #fff
  border-bottom: 1px solid #ddd !important
  height: 64px !important
  .router-tab__scroll
    position: relative
    top: 31px
  .dashboard-fixed
    opacity: 0.6
    padding: 0px 7px
    color: #4d4d4d
    font-size: 11px
    position: relative
    top: 39px
    img
      position: relative
      top: -1px
      margin-right: 3px
      background: #4d4d4d
  .dashboard-fixed:hover
    opacity: 1
    color: #be4178
    img
      background: #be4178
  .dashboard-fixed.active
    opacity: 1
    color: #be4178
    font-weight: 600
    img
      background: #be4178
  .user-profile
    .username
      color: #525252
      font-size: 18px
      font-weight: 500
    .ant-col
      padding: 0 20px
      border-left: 1px solid #ddd !important
      line-height: 61px
      background: #FFF
  .dashboard-item
    display: none
  .router-tab__item
    border: 0 !important
    background: #FFF !important
    img
      background: #4d4d4d
  .router-tab__item:hover
    img
      background: #be4178
  .router-tab__item.is-active
    border: 0 !important
    font-weight: 600
    position: relative
    top: 0px
    z-index: 9999
    img
      background: #be4178
</style>
