<template>
  <div>
    <div>
      <ul>
        <li
          v-for="(item, index) in submenu"
          :key="index"
          :class="
            `${item.class} ${
              $route.meta.module == item.url ? ' active' : ''
            }  ${item.disabled ? ' disabled' : ''}`
          "
          @click="$router.push(item.url)"
        >
          <div :class="item.url ? '' : 'disable-click'" class="item">
            {{ item.itemName }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submenu: Array,
  },
  mixins: [],
};
</script>

<style lang="sass" scoped>
.nav
  float: left
  width: 100%
  color: #fff
  ul
    padding: 0
    margin: 0
    list-style: none
    li
      position: relative
      margin-bottom: 1px
      display: block
      padding: 18px 0
      line-height: 1
      opacity: 1
      transition: .3s
      cursor: pointer
      text-align: center
      height: auto
      text-align: center
      max-height: 88px
      transition: .3s
      &.active
        background: #303842
      &.up
        .submenu
          top: -50px
        .sub.submenu
          top: unset !important
          bottom: -7px
      img
        opacity: 0.5
        height: 26px
      .submenu
        display: none
        position: absolute
        z-index: 999
        right: -201px
        padding: 6px 0
        border-radius: 6px
        top: 0
        width: 200px
        background: #434A53
        ul
          padding: 0
          li
            position: relative
            padding: 0
            .anticon-right
              position: absolute
              right: 10px
              opacity: 0.3
            .submenu.sub
              display: none
            .item
              display: block
              padding: 10px 0
              border-bottom: 1px solid rgba(255,255,255,0.05)
              transition: .3s
              font-size: 12px
              color: #AAB2BC
              line-height: 1
            &.disabled
              opacity: 0.4
              pointer-events: none
            &:hover
              background: #303842
              .submenu.sub
                display: block
          //   background: red !important
          //   border-radius: 5px 5px 0 0
      &.disabled
        opacity: 0.4
        pointer-events: none
      &.router-link-active
        background: #303842
      &:hover
        background: #303842
        opacity: 1
        img
          opacity: 1
        .name
          color: #fff
        .submenu
          display: block
      .img
        margin-bottom: 11px
      .name
        font-size: 12px
        color: #AAB2BC
        line-height: 1
</style>
