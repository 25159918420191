var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aLayoutSider',{staticStyle:{"background":"#434a53"}},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',{staticClass:"a-center",attrs:{"span":24}},[_c('span',{on:{"click":function($event){return _vm.$router.push(`/`)}}},[_c('img',{staticClass:"logo c-pointer",attrs:{"alt":"Haya","src":require("@/assets/images/menu/haya-ico.png")}})])]),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('div',{staticClass:"nav"},[_c('ul',_vm._l((_vm.systemMenu),function(item,index){return _c('div',{key:index},[(item.show === true)?_c('li',{class:`${item.class} ${
                _vm.$route.meta.module == item.class ? ' active' : ''
              } ${
                item.class == 'admin' || item.class == 'miles-cards'
                  ? ' up'
                  : ''
              }  ${item.disabled ? ' disabled' : ''}`},[_c('div',{class:item.url ? '' : 'disable-click',on:{"click":function($event){return _vm.$router.push(item.url)}}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"alt":item.itemName,"src":item.icoImg}})]),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v("\n                  "+_vm._s(item.itemName)+"\n                ")])]),_vm._v(" "),(item.subMenu.length > 0)?_c('div',{staticClass:"submenu"},[_c('ul',_vm._l((item.subMenu),function(item,index){return _c('div',{key:index},[(item.show === true)?_c('li',{class:`${item.class} ${
                        _vm.$route.meta.module == item.url ? ' active' : ''
                      }  ${item.disabled ? ' disabled' : ''}`},[_c('div',{staticClass:"item",class:item.url ? '' : 'disable-click',on:{"click":function($event){return _vm.$router.push(item.url)}}},[_vm._v("\n                        "+_vm._s(item.itemName)+"\n                        "),(item.subSubMenu.length > 0)?_c('a-icon',{attrs:{"type":"right"}}):_vm._e()],1),_vm._v(" "),(item.subSubMenu.length > 0)?_c('div',{staticClass:"submenu sub"},[_c('ul',_vm._l((item.subSubMenu),function(item,index){return _c('li',{key:index,class:`${item.class} ${
                              _vm.$route.meta.module == item.url ? ' active' : ''
                            }  ${item.disabled ? ' disabled' : ''}`,on:{"click":function($event){return _vm.$router.push(item.url)}}},[(item.show === true)?_c('div',{staticClass:"item",class:item.url ? '' : 'disable-click'},[_vm._v("\n                              "+_vm._s(item.itemName)+"\n\n                              "),(item.subSubMenu != undefined)?_c('span',[(item.subSubMenu.length > 0)?_c('a-icon',{attrs:{"type":"right"}}):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),(item.subSubMenu != undefined)?_c('div',[(item.subSubMenu.length > 0)?_c('NavigationSubMenu',{staticClass:"submenu subsub",attrs:{"submenu":item.subSubMenu}}):_vm._e()],1):_vm._e()])}),0)]):_vm._e()]):_vm._e()])}),0)]):_vm._e()]):_vm._e()])}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }