var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" Voltar\n    ")],1),_vm._v(" "),_c('h1',{staticClass:"mt-30"},[_vm._v("Alterar Senha")]),_vm._v(" "),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                    rules: [
                        {
                            required: true,
                            message: 'Escreva sua nova senha.',
                        },
                    ],
                },
            ]),expression:"[\n                'password',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message: 'Escreva sua nova senha.',\n                        },\n                    ],\n                },\n            ]"}],staticClass:"travel-input",attrs:{"size":"large","type":"password","placeholder":"Insira sua nova senha"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirm',
                {
                    rules: [
                        {
                            required: true,
                            message: 'Repita a sua nova senha.',
                        },
                        {
                            validator: _vm.compareToFirstPassword,
                        },
                    ],
                },
            ]),expression:"[\n                'confirm',\n                {\n                    rules: [\n                        {\n                            required: true,\n                            message: 'Repita a sua nova senha.',\n                        },\n                        {\n                            validator: compareToFirstPassword,\n                        },\n                    ],\n                },\n            ]"}],staticClass:"travel-input",attrs:{"size":"large","type":"password","placeholder":"Repita sua nova senha"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v("\n            Enviar\n        ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }