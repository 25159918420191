import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    tokenExperation: localStorage.getItem("tokenExpiration"),
    isLoggedIn: localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : false,
    userData: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : "",
    capabilities: [],
    newContract: {},
    contract: {
      data: {},
    },
  },
  mutations: {
    SET_CAPABILITIES(state, capabilities) {
      state.capabilities = capabilities;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_TOKEN_EXPIRATION(state, tokenExpiration) {
      state.tokenExpiration = tokenExpiration;
    },
  },
  actions: {
    setToken(context) {
      context.commit("SET_TOKEN", localStorage.getItem("token"));
    },
    setTokenExpiration(context) {
      context.commit(
        "SET_TOKEN_EXPIRATION",
        localStorage.getItem("tokenExpiration")
      );
    },
    setCapabilities(context) {
      axios
        .post(
          "https://api.viajar.tur.br/v1/option/get",
          {
            id: 11,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          context.commit(
            "SET_CAPABILITIES",
            JSON.parse(data.value).capabilities
          );
        })
        .catch(({ res }) => {
          res;
        });
    },
  },
  modules: {},
  getters: {
    getCapabilities(state) {
      return state.capabilities;
    },
  },
});
